<script>
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main'
import WorkdayChecksResumeService from '@src/services/WorkdayChecksResumeService'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatters from '@src/mixins/vuetableFormatters.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import pointsReportFilterModal from '@/src/router/views/reports/points/pointsReportFilterModal.vue'
import NoRecordsFound from '@/src/components/NoRecordsFound'
import isOdd from '@utils/is-odd'
import moment from 'moment'

const i18nCommon = 'COMMON'
const i18nKey = 'WORKDAYS_BREAKS'

export default {
    components: {
        Breadcrumb,
        PageHeader,
        Layout,
        FilterLabelButton,
        pointsReportFilterModal,
        NoRecordsFound,
    },
    mixins: [
        swalFeedback,
        pagination,
        filterVuetable,
        vuetableFormatters,
    ],
    data() {
        return {
            i18nCommon,
            i18nKey,
            isOdd,
            loading: false,
            additionalParameters: {},
            lastPage: 999,
            currentPage: 1,
            perPage: 7,
            totalItems: 0,
            filters: {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                user: [],
            },
            users: [],
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'point',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18Point() {
            return this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'point',
                        modifier: 2,
                    })
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nWork() {
            return this.getI18n(i18nCommon, 'work')
        },
        i18nWorkdayBreak() {
            return this.getI18n('WORKDAYS_BREAKS', 'TITLES.workday_break')
        },
        i18nTotal() {
            return this.getI18n('PAGINATION', 'total')
        },
    },
    watch: {
        currentPage(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.filter()
            }
        }
    },
    mounted() {
        this.filter()
    },
    methods: {
        createAdditionalParameters() {
            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                per_page: this.perPage,
                page: this.currentPage,
                user_id: this.filters.user.map((unit) => unit.id),
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.additionalParameters = this.createAdditionalParameters()
            this.refresh()
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.pointsReportFilterModal.showModal(this.filters))
        },
        refresh() {
            this.loading = true

            WorkdayChecksResumeService.fetchAll(this.additionalParameters)
                .then((response) => {
                    this.users = response.data.data
                    this.lastPage = response.data.meta.last_page
                    this.totalItems = response.data.meta.total
                    this.perPage = response.data.meta.per_page

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                        <!-- <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="workday_break"
                            :create-parameters="createAdditionalParameters"
                        /> -->
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18Point" />
                <div class="card-body">
                    <b-skeleton-table
                        v-if="loading"
                        :rows="4"
                        :columns="10"
                        :table-props="{
                            hover: true,
                            noBorderCollapse: true,
                        }"
                    />
                    <div v-if="users.length <= 0 && !loading">
                        <no-records-found />
                    </div>
                    <b-table-simple
                        v-if="users.length > 0 && !loading"
                        hover
                        show-empty
                        sticky-header="900px"
                    >
                        <b-thead>
                            <b-tr>
                                <b-th
                                    sticky-column
                                    style="background-color: #fff;"
                                />
                                <b-th
                                    v-for="(item, index) in users[0].items"
                                    :key="index"
                                    class="text-center"
                                    :style="`background-color: ${isOdd(index) ? '#fcfcfc' : '#fff'}`"
                                    colspan="3"
                                >
                                {{ formatDate(item.date) }}
                                </b-th>
                            </b-tr>
                            <b-tr>
                                <b-th
                                    sticky-column
                                    style="background-color: #fff;"
                                >
                                    {{ i18nUser }}
                                </b-th>
                                <template v-for="(item, index) in users[0].items">
                                    <b-th
                                        :key="`${index}-work`"
                                        :style="`background-color: ${isOdd(index) ? '#fcfcfc' : '#fff'}`"
                                        class="text-center"
                                    >
                                        {{ i18nWork }}
                                    </b-th>
                                    <b-th
                                        :key="`${index}-interval`"
                                        :style="`background-color: ${isOdd(index) ? '#fcfcfc' : '#fff'}`"
                                        class="text-center"
                                    >
                                        {{ i18nWorkdayBreak }}
                                    </b-th>
                                    <b-th
                                        :key="`${index}-total`"
                                        :style="`background-color: ${isOdd(index) ? '#fcfcfc' : '#fff'}`"
                                        class="text-center"
                                    >
                                        {{ i18nTotal }}
                                    </b-th>
                                </template>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="user in users" :key="user.id">
                                <b-td sticky-column
                                style="background-color: #fff;">
                                    {{ user.user_name }}
                                </b-td>
                                <template v-for="(item, index) in user.items">
                                    <template v-if="isOdd(index)">
                                        <b-td
                                            :key="`${user.id}-${index}-work`"
                                            style="background-color: #fcfcfc"
                                            class="text-center"
                                        >
                                            {{ item.work }}
                                        </b-td>
                                        <b-td
                                            :key="`${user.id}-${index}-interval`"
                                            style="background-color: #fcfcfc"
                                            class="text-center"
                                        >
                                            {{ item.interval }}
                                        </b-td>
                                        <b-td
                                            :key="`${user.id}-${index}-total`"
                                            style="background-color: #fcfcfc"
                                            class="text-center"
                                        >
                                            {{ item.total }}
                                        </b-td>
                                    </template>
                                    <template v-else>
                                        <b-td
                                            :key="`${user.id}-${index}-work`"
                                            class="text-center"
                                        >
                                            {{ item.work }}
                                        </b-td>
                                        <b-td
                                            :key="`${user.id}-${index}-interval`"
                                            class="text-center"
                                        >
                                            {{ item.interval }}
                                        </b-td>
                                        <b-td
                                            :key="`${user.id}-${index}-total`"
                                            class="text-center"
                                        >
                                            {{ item.total }}
                                        </b-td>
                                    </template>
                                </template>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-row class="justify-content-end">
                        <b-pagination
                            v-model="currentPage"
                            class="mr-2"
                            :total-rows="totalItems"
                            :per-page="perPage"
                        />
                    </b-row>
                </div>
            </div>
        </div>
        <points-report-filter-modal
            ref="pointsReportFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
