<script>
export default {
    components: {},
    props: {
        handleOpen: {
            type: Function,
            required: false,
            default: function () {},
        },
        handleHide: {
            type: Function,
            required: false,
            default: function () {},
        },
        handleClose: {
            type: Function,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        formData: {
            type: Object,
            required: false,
            default: () => {},
        },
        size: {
            type: String,
            default: 'md',
        },
        inEditing: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {}
    },
    computed: {},
    beforeMount() {},
    mounted() {},
    methods: {
        show() {
            this.$refs.modalResponsive.show()
        },

        hide() {
            this.$emit('handleHide');
            this.$refs.modalResponsive.hide()
        },

        close() {
            if (this.handleClose) {
                this.$props.handleClose()
            }
            else {
                this.hide()
            }
        },
    },
}
</script>
<template>
    <div>
        <b-modal
            id="modalResponsive"
            ref="modalResponsive"
            centered
            :no-close-on-esc="inEditing"
            :no-close-on-backdrop="inEditing"
            :no-enforce-focus="true"
            :size="size"
            @show="handleOpen"
            @hidden="hide"
        >
            <slot name="form"/>
            <div class="bottom-content small">
                <slot name="observation"/>
            </div>
            <template v-slot:modal-header>
                <h4 class="modal-title">
                    {{ title }}
                </h4>
                <button class="close" @click="close">
                    <i class="fe-x-circle icon-blue"/>
                </button>
            </template>
            <template v-slot:modal-footer>
                <div class="col text-center ml-3 mr-3">
                    <slot name="footer"/>
                </div>
            </template>
        </b-modal>
    </div>
</template>
