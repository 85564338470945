import BaseService from '@src/services/BaseService'

class WorkdayChecksResumeService extends BaseService {
  constructor() {
    super('workday-checks-resume')
  }

}

export default new WorkdayChecksResumeService()
